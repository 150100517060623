body{
  overflow: overlay;
}

.App {
  text-align: center;
}


::-webkit-scrollbar{
  width: 10px;
}

::-webkit-scrollbar-track{
  background-color: rgba(0,0,0,0);
}

::-webkit-scrollbar-thumb{
  background-color: rgba(0,0,0,0.4);
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: content-box;

}